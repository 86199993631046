<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          MAWB
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        ></v-text-field> -->

        <v-col cols="2">
          <v-text-field
            class="mt-5"
            v-model="busAwb"
            label="AWB"
            clearable
            @keyup.enter="cargarLista()"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <!-- <v-text-field
            class="mt-5"
            v-model="busCliente"
            label="Cliente"
            clearable
            @keyup.enter="cargarLista()"
          ></v-text-field> -->
          <v-autocomplete
            class="mt-5"
            label="Clientes"
            v-model="busCliente"
            :items="clientes"
            item-text="CNE_NOMBRE"
            item-value="ENTE_ID"
            clearable
            @change="cargarLista()"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-select
            class="mt-5"
            v-model="busEstado"
            :items="estados"
            item-text="descripcion"
            item-value="estado_id"
            label="Estado"
            clearable
            @change="cargarLista()"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="nuevaGuia()">
          <v-icon left>mdi-plus-circle-outline</v-icon> Nueva Guia
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="listaMawb"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="green" @click="editarMawb(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo GUIA -->
    <v-dialog v-model="dialogMawb" persistent max-width="860px" scrollable>
      <v-card>
        <v-toolbar height="40" color="blue" dark flat fixed>
          <v-icon left class="mt-1">mdi-truck</v-icon>
          <span class="mt-1 headline">AWB # {{ datosMawb.AWB_NUM }}</span>
          <v-divider></v-divider>
          <v-btn @click="dialogMawb = !dialogMawb" icon dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formMawb">
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="requiredRule"
                    type="date"
                    v-model="datosMawb.FECHA_VUELO"
                    label="Fecha"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-if="datosMawb.AWB_NUM"
                    v-model="datosMawb.AWB_NUM"
                    label="NUM GUIA"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-autocomplete
                    :rules="requiredRule"
                    v-model="datosMawb.CNEE_ID"
                    :items="clientes"
                    label="Cliente"
                    item-text="CNE_NOMBRE"
                    item-value="ENTE_ID"
                  >
                  </v-autocomplete>
                  <!-- <v-text-field v-model="datosMawb.CNE_NOMBRE" label="Cliente">
                  </v-text-field> -->
                </v-col>

                <v-col class="pt-0 pb-0" cols="6"> </v-col>
              </v-row>

              <!-- <v-row>
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field v-model="datosPodHead.ESTADO_ID" label="Estado">
                    </v-text-field>
                  </v-col>
                </v-row> -->

              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :items="guiasHijas"
                    :headers="headerGuiasHijas"
                    :items-per-page="items999"
                  >
                    <template v-slot:item.opcion="{ item }">
                      <v-btn small color="red" icon
                        ><v-icon left>mdi-delete</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarMawb()">
              <v-icon left>mdi-content-save</v-icon> Guardar
            </v-btn>

            <!-- <v-btn color="blue" dark class="mx-4" small @click="imprimirPod">
              <v-icon left>mdi-printer</v-icon> Imprimir
            </v-btn> -->

            <v-btn class="mx-8" small @click="dialogMawb = !dialogMawb">
              <v-icon left>mdi-cancel</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
var intervalbarCode = "";
export default {
  name: "Mawb",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "ID#", value: "MAWB_ID" },
      { text: "AWB#", value: "AWB_NUM" },
      { text: "Fecha vuelo", value: "FECHA_VUELO" },
      { text: "Consignatario", value: "CNEE_NOMBRE" },
      { text: "Estado", value: "ESTADO_ID" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    search: "",
    listaMawb: [],
    options: {},
    pageCount: 0,
    dialogMawb: false,
    widgets: true,
    busAwb: "",
    busCliente: "",
    busEstado: "",

    datosMawb: {
      MAWB_ID: "",
      FECHA_VUELO: "",
      ESTADO_ID: "",
    },

    guiasHijas: [],
    headerGuiasHijas: [
      { text: "Opciones", value: "opcion" },
      { text: "HAWB", value: "NUM_GUIA_HIJA" },
      { text: "FINCA / REM", value: "FINCA_NOMBRE" },
    ],

    estados: [
      { estado_id: "CREADA", descripcion: "CREADA" },
      { estado_id: "RECIBIDA", descripcion: "RECIBIDA" },
    ],

    itemsPerPage: 40,
    items999: 9999,
    currentPage: 1,
    clientes: [],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-guias-master");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          busCliente: this.busCliente,
          busAwb: this.busAwb,
          busEstado: this.busEstado,
        },
      })
        .then((res) => {
          //console.log(res);
          this.listaMawb = res.data.lista;
          this.pageCount = res.data.pageCount;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarGuia() {
      this.setUrl("api/modificar-guia-master");
      this.requestApi({
        method: "POST",
        data: {
          datosMawb: this.datosMawb,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogMawb = false;
        })
        .then(() => {});
    },

    /*abrirDialogPod(podId) {
      window.location.target = "_new";
      window.location.href = "/form_pod/" + podId;
    },*/

    eliminarMawb(mawbId) {
      Vue.swal({
        html: "Está seguro de eliminar el pod ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-guia-master");
          this.requestApi({
            method: "POST",
            data: {
              MAWB_ID: mawbId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarMawb() {
      if (!this.$refs.formMawb.validate()) {
        return false;
      }
      if (this.datosMawb.MAWB_ID == null || this.datosMawb.MAWB_ID == "") {
        this.crearMawb();
      } else {
        this.modificarMawb();
      }
    },
    crearMawb() {
      this.setUrl("api/crear-guia-master");
      this.requestApi({
        method: "POST",
        data: {
          datosMawb: this.datosMawb,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogMawb = false;
          this.cargarLista();
        })
        .then(() => {});
    },

    cargarMawb(mawbId) {
      //console.log(this.datosPod);
      this.setUrl("api/datos-guia-master");
      this.requestApi({
        method: "GET",
        data: {
          MAWB_ID: mawbId,
        },
      })
        .then((res) => {
          //this.datosPodHead = res.data.podHead;
          this.datosMawb = res.data.datos_mawb;
          this.guiasHijas = res.data.guias_hijas;
        })
        .then(() => {});
    },

    imprimirPod() {
      this.setUrl("api/imprimir-pod");
      this.requestApi({
        method: "POST",
        data: {
          POD_ID: this.datosPodHead.POD_ID,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
          this.itemsSel = [];

          //this.alertNotification({ param: { html: res.data.msg } });
        })
        .then(() => {});
    },
    nuevaGuia() {
      this.datosMawb = {
        FECHA_VUELO: new Date().toISOString().substring(0, 10),
      };
      this.dialogMawb = true;
    },
    editarMawb(item) {
      this.cargarMawb(item.MAWB_ID);
      this.dialogMawb = true;
    },
    cargarDatosForm() {
      this.setLoadingTable(true);
      this.setUrl("api/form-guias-hijas");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res);
          //this.guiasMadres = res.data.guiasMadres;
          //this.fincas = res.data.fincas;
          this.clientes = res.data.clientes;
          //this.tiposCaja = res.data.tipos_caja;
          this.setLoadingTable(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChange() {
      this.cargarLista();
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Guias madres MAWB");
    this.cargarDatosForm();
    this.cargarLista();
  },
};
</script>
